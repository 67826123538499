<template>
  <v-card flat tile :loading="loading">
    <v-container>
      <v-card class="mb-4" outlined>
        <v-system-bar>E-Mail</v-system-bar>
        <div class="text-right"><EmailClient /></div>
        <ListItem
          v-for="dl in dls"
          v-bind:key="dl.description"
          :align="dl.emails.length > 2 ? 'start' : 'center'"
          :title="dl.description.replace('E-Mail an', '').replace(' von', '')"
        >
          <Mailto :dl="dl.emails" :bcc="dl.bcc"
            >{{ dl.emails.join(", ") }}
          </Mailto>
        </ListItem>
      </v-card>
      <ContactPersonal class="mb-4" outlined :person="person" />
      <ContactParents outlined :person="person" />
    </v-container>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import { saveChildRoute } from "@/router/helper";
import EmailClient from "@/components/settings/EmailClient.vue";
import Mailto from "@/components/Mailto.vue";
import ContactPersonal from "common/components/Person/ContactPersonal.vue";
import ContactParents from "common/components/Person/ContactParents.vue";
import ListItem from "@/components/ListItem.vue";

export default defineComponent({
  name: "PersonContact",
  components: {
    ContactParents,
    ContactPersonal,
    EmailClient,
    ListItem,
    Mailto,
  },
  props: {
    id: { type: String },
  },
  computed: {
    personId() {
      return Number.parseInt(this.id);
    },
  },
  data() {
    return {
      person: {},
      dls: [],
      loading: false,
    };
  },
  methods: {
    async getData() {
      if (this.personId) {
        this.loading = true;
        this.person = await this.apiGet({
          resource: "person/person",
          id: this.personId,
        });
        this.dls = await this.apiGet({
          resource: "person/email",
          id: this.personId,
        });
        this.loading = false;
      }
    },
  },
  watch: {
    async id() {
      await this.getData();
    },
  },
  async created() {
    await this.getData();
  },
  beforeRouteUpdate(to, from, next) {
    saveChildRoute(to, next);
  },
});
</script>
